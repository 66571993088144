html {
    scroll-padding-top: 100px; 
    scroll-behavior: smooth;

}

body, p, td, th, input, select, option {
    font-family: "Futura Light", "Trebuchet MS", Arial, sans-serif;
    font-size: 20px;
    color: rgb(128, 128, 128);
}

#wrap {
    position: fixed;
    top: 0px;
    width: 100%;
}

#topNavFrame {
    position: relative;
    top: 0px;
    border-bottom: 1px solid rgb(224, 224, 224);
    background: white;
}

.topNav {
    margin: 0 auto;
    overflow: hidden;
    max-width: 1000px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 0px;

    height: 43px;
}

#logo {
    float: left;
    display: block;
}

.topNavLink {
    height: 35px;
    line-height: 35px;
    float: left;
    display: block;
    color: rgb(164, 164, 164);
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 5px;
    border-bottom: 3px solid white;
    text-decoration: none;
    font-size: 14px;
}

.topNavLinkSelected, .topNavLink:hover {
    color: #0577c7;
    border-bottom: 3px solid #0577c7;
}

#topNavRight {
    float: right;
}

a {
    text-decoration: none;
    color: #0577c7;
}



a#howItWorksLink {
    margin-left: 80px;
    margin-right: 30px;
}

a#coursesLink {
    margin-right: 30px;
}

a#pedagogyLink {
    margin-right: 30px;
}


a#faqLink {
    margin-right: 30px;
}


.topNav .icon {
    display: none;
}

a#loginLink {
    float: left;
}

span#signUpButton {
    float: left;
    padding: 10px 12px 10px 12px;
    line-height: 15px;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    background: #0577c7;
    color: white;
    cursor: pointer;
    margin-left: 15px;
}

span#signUpButton:hover {
    opacity: 0.9;
}

div#mobileMenuIcon {  
    display: none;  
    float: left;
    margin-left: 20px;
    width: 34px;
    height: 34px;
    
    background-repeat: no-repeat;  
    overflow: hidden;
    cursor: pointer;
}

div#mobileMenuIcon:hover {
    float: left;
    width: 34px;
    height: 34px;
    
    background-repeat: no-repeat;  
    overflow: hidden;
    background-position: -34px 0px;   
}

div#mobileMenu {
    position: fixed;  
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    background: white;
    border-right: 1px solid rgb(224, 224, 224);
    box-sizing: border-box;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s ease-in-out;
    overflow: auto;
    user-select: none;
}

div#mobileMenu.show {
    display: block;
    transform: translate3d(0vw, 0, 0);
}

div#mobileMenuCloseButton {
    float: right;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;  
    overflow: hidden;
    cursor: pointer;
}

div#mobileMenuCloseButton:hover {
    background-position: 0px 0px;   
}

div#mobileMenuHeader {
    margin-bottom: 60px;
}

div.mobileMenuItem {
    font-size: 26px;
    font-weight: bold;
    padding-left: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(224, 224, 224);
    color: rgb(30, 25, 78);
}

div.mobileMenuItem a {
    color: rgb(30, 25, 78);
    text-decoration: none;
}


div.mobileMenuItem:last-child {
    border-bottom: none;
}

@media screen and (max-width: 600px) {
    a#howItWorksLink {
        display: none;
    }
    a#coursesLink {
        display: none;
    }
    div#mobileMenuIcon {
        display: block;
    }
    table{
        width: 100%;
    }
    td{
        display: block;
        width: 100%;
    }
    tr {
        display: block;
        margin-bottom: 30px;
    }    
}
a#faqLink {
    color: #0577c7;
    border-bottom: 3px solid #0577c7;
}

h1 {
    margin-top: 90px;
}

h3.question {
    margin-top: 40px;
}



body, p, td, th, input, select, option {
    font-family: "Futura Light", "Trebuchet MS", Arial, sans-serif;
    font-size: 20px;
    color: rgb(128, 128, 128);
}

div.headerFrame {
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
}

header {
    margin: 0 auto;
    max-width: 1000px;
}

div.sectionFrame {
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgb(224, 224, 224);
    margin-bottom: 50px;
}

[class~='sectionFrame']:last-of-type  {
    border-bottom: none;
    margin-bottom: 0px;
}

section {
    margin: 0 auto;
    max-width: 1000px;
    padding-bottom: 50px;
}

h1 {
    font-size: 48px;
    font-weight: normal;
    color: rgb(30, 25, 78);
    text-align: center;
    margin-bottom: 10px;
}

h2 {
    font-size: 32px; 
    font-weight: normal;
    color: rgb(30, 25, 78);
    text-align: center;
    margin-bottom: 50px;
}

h3 {
    font-size: 24px; 
    font-weight: normal;
    color: rgb(30, 25, 78);
    text-align: left;
}

h4 {
    font-size: 20px; 
    font-weight: normal;
    color: rgb(30, 25, 78);
    text-align: left;
    margin-top: 30px;
}

li {
    margin-bottom: 20px;
}

div.screenshot {
    display: block;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05);  
}

div.screenshot {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

/* footer ***********************************************************************/

footer {
    border-top: 1px solid rgb(224, 224, 224);
    margin-top: 50px;
    padding-top: 50px;    
    padding-bottom: 50px;
    text-align: center;
    font-size: 16px;
}

div#support {
    display: block;
    text-align: center;
    margin-bottom: 30px;
}

div#copyright {
    display: block;
    text-align: center;
    margin-bottom: 30px;
}

div.footerLinks {
    display: block;
    text-align: center;    
}

footer a {
    margin-right: 15px;
    color: rgb(128, 128, 128);
}


.toggle-link {
    display: none;
    margin-top: 5px;
}

.question span {
    cursor: pointer;
}

.question span:hover {
    color: darkblue;
}

.question-link-title{
    color: rgb(30, 25, 78);
}
.toggle-button{
    margin-left: 10px;
    padding-bottom: 10px;
    display: table-cell; 
    vertical-align: middle
    
}
ul ul{
    margin-left:20px;
  }
.return-to-top-button{

    display: none; 
    position: fixed; /* Fixed/sticky position */
    top: 20px; /* Place the button at the bottom of the page */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color:  rgb(128, 128, 128,0.4); /* Set a background color */
    color: rgb(30, 25, 78);; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 14px; /* Increase font size */
    margin-right:300px;

}   
.content_list{
    float: left;
    margin-left: 100px;
}